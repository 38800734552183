<template>
  <div class="col-12">
    <br>
    <div class="card">
        <div class="card-header">
            <h5>
                <span>تقرير تحضير دروس المعلمين بتاريخ <i id="date"></i></span>
                &nbsp;
                <b-dropdown
                id="dropdown-2"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="خيارات"
                variant="success"
                >
                <b-dropdown-item @click="sendToTeachers()">
                    <i class="fa fa-paper-plane"></i>
                    ارسال للمعلمين بعدم تحضير الدروس
                </b-dropdown-item>
                <b-dropdown-item @click="sendToTeachers3()">
                    <i class="fa fa-paper-plane"></i>
                    ارسال للمعلمين بالدورس الغير متزامنة
                </b-dropdown-item>
                <b-dropdown-item @click="viewdetails()">
                    <i class="fa fa-eye"></i>
                    عرض تفاصيل كل الدروس
                </b-dropdown-item>
                </b-dropdown>
                &nbsp;
                <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="📥 طباعة / تصدير"
                variant="primary"
                >
                <b-dropdown-item @click="printDiv()">
                    <i class="fa fa-print"></i>
                    طباعة التقرير
                </b-dropdown-item><hr>
                <b-dropdown-item @click="printDiv2()">
                    <i class="fa fa-print"></i>
                    طباعة ملونة
                </b-dropdown-item><hr>
                <b-dropdown-item @click="exportToExcel()">
                    <i class="fa fa-download"></i>
                    تصدير التقرير اكسل
                </b-dropdown-item><hr>
                <b-dropdown-item @click="saveToArchive()">
                    <i class="fa fa-archive"></i>
                    حفط في سجل التقارير
                </b-dropdown-item>
                </b-dropdown>
                &nbsp;
                <button class="btn btn-secondary" style="background: #39A7FF !important" @click="datasGet()" v-b-modal.modal-datas>
                    <i class="fa fa-eye"></i>
                    عرض العناصر الغير معدة
                </button>
            </h5>
        </div>
        <div class="card-body" id="headerTable">
            <div class="col-12 table-responsive" >
                <span class="dnone">تقرير تحضير دروس المعلمين بتاريخ <span id="date2"></span></span>
                <table class="table table-hover table-bordered ddcc table-sm">
                    <thead>
                        <th>م</th>
                        <th>الاسم</th>
                        <th>الأولى</th>
                        <th>الثانية</th>
                        <th>الثالثة</th>
                        <th>الرابعة</th>
                        <th>الخامسة</th>
                        <th>السادسة</th>
                        <th>السابعة</th>
                        <th>الثامنة</th>
                        <th>التاسعة</th>
                        <th>العاشرة</th>
                    </thead>
                    <tbody></tbody>
                </table>
            </div>
        </div>
    </div>
    <textarea class='get-local-storage' name="teachersLessonsHTML" style='display: none'></textarea>
    <b-modal id="modal-teachers-lessons-send" size="lg" title="ارسال رسائل عدم تحضير الدروس" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>

    <b-modal id="modal-teachers-lessons-send3" size="lg" title="ارسال رسائل" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <b-modal id="modal-teachers-lessons-send2" size="lg" title="ارسال رسائل الدروس الغير معدة" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="teachers-lessons-send" @click="message = `الاستاذ {name}
لم يتم اعداد الحصص التالية:
{lessons}
ليوم {day} الموافق {date}`; teachersMessages(); "
      v-b-modal.modal-teachers-lessons-send
    ></button>
    <button
      class="dnone"
      id="teachers-lessons-send2"
      v-b-modal.modal-teachers-lessons-send2
    ></button>
    <button
      class="dnone"
      id="teachers-lessons-send3" @click=" teachersMessages3(); "
      v-b-modal.modal-teachers-lessons-send3
    ></button>
    <b-modal id="modal-datas" size="lg" title="العناصر الغير معدة للدروس" hide-footer>
        <div class="col-12 g text-center" v-if="datas_arr.length == 0">
            <div class="alert alert-warning text-dark g">
                تأكد من تفعيل خيار "الاستعلام عن العناصر الغير معدة في الدروس" قبل استعراض التقرير.
                <br>
                او تاكد ان هناك دروس معدة في التقرير
            </div>
        </div>
        <div class="col-12 g">
            <button class="btn btn-primary btn-sm" @click="printb()">
                <i class="fa fa-print"></i>
                طباعة هذه الصفحة
            </button>
            &nbsp;
            <button class="btn btn-success btn-sm" @click="message = `الاستاذ {name}
لم يتم اعداد العناصر التالية:
{items}
--------
ليوم {day} الموافق {date}`; senddates()">
                <i class="fa fa-paper-plane"></i>
                ارسال للمعلمين
            </button>
        </div>
        <div class="col-12 table-responsive" id="printb">
            <h5 class="dnone">
                العناصر الغير معدة في تقرير تحضير الدروس   <span id="date3"></span>
            </h5> 
            <table class="table table-bordered table-hover table-sm">
                <thead>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الفصل
                    </th>
                    <th>
                        الحصة
                    </th>
                    <th>
                        العناصر
                        الغير معدة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="d in datas_arr" :key="d.teacher + d.classname + d.lesson">
                        <td>
                            {{ d.teacher }}
                        </td>
                        <td>
                            {{ d.classname }}
                        </td>
                        <td>
                            {{ d.lesson }}
                        </td>
                        <td>
                            <ul>
                                <li v-if="d.data.ethrat == 0">
                                    الاثراءات
                                </li>
                                <li v-if="d.data.homeworks == 0">
                                    الواجبات
                                </li>
                                <li v-if="d.data.exams == 0">
                                    الاختبارات
                                </li>
                                <li v-if="d.data.actives == 0">
                                    النشاطات
                                </li>
                                <li v-if="d.data.pre == 0">
                                    التهيئة
                                </li>
                                <li v-if="d.data.voc == 0">
                                    مفردات الدرس
                                </li>
                                <li v-if="d.data.strat == 0">
                                    استراتيجيات
                                </li>
                                <li v-if="d.data.tools == 0">
                                    الوسائل
                                </li>
                                <li v-if="d.data.skills == 0">
                                    مهارات
                                </li>
                                <li v-if="d.data.close == 0">
                                    الاغلاق
                                </li>
                                <li v-if="d.data.notes == 0">
                                    التعليمات
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            view_archive: false,
            selected: null,
            school_id: localStorage.getItem("school_id"),
            dayindex: window.location.href.includes('dayindex') ? window.location.href.split("dayindex=")[1].split("&")[0] : '',
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
            datas: {},
            lessons: {},
            teachers: [],
            datas_arr: [],
            result: [],
            message: ''
        }
    },
    created(){
        var g = this;
        if(window.location.href.includes("report_id")){
            $.post(api + '/api/reports/one', {
                school_id: g.school_id,
                id: window.location.href.split("report_id=")[1]
            }).then(function(r){
                for (const [key, value] of Object.entries(JSON.parse(r.response))) {
                    g.$data[key] = value
                }
                var result = g.result
                var index = 1;
                $.each(result, function (i, teacher) {

                    $("#date").html(teacher.data.CurrentSelectedDateStringHijri + " || " + teacher.data.CurrentSelectedDateString)

                    if (teacher.data.TimeTable[0]) {

                        var lessons = "";
                        var details = "";
                        var ua = "";

                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(function (i) {

                            lessons = lessons + "<td>";

                            $.each(teacher.data.TimeTable, function (ia, table) {

                                if (table.SlotNumber == i) {

                                    if (table.PresenterUrl != null && table.PresenterUrl != "") {

                                        lessons = lessons + "<span data="+table.Data+" class='btn btn-block btn-success aa btn-sm'>√ <strong>" + table.ClassRoomName + "</strong></span>";
                                        ua = `
                                    <small>
                                    رابط Teams: <a target='_blank' href='` + table.PresenterUrl + `'>إضغط هنا</a>
                                    </small>
                                    <br>
                                    <small>
                                    تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                    </small>
                                    <br>
                                    `;

                                    } else {

                                        if (table.LessonType == 2) {

                                            lessons = lessons + "<span data="+table.Data+" class='btn btn-block btn-info aa btn-sm'>√ <strong>" + table.ClassRoomName + "</strong> <small class='dnone'><br>غير متزامن</small></span>";
                                            ua = `<small class='text-primary'>
                                                غير متزامن
                                                <br>
                                    <small>
                                    تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                    </small>
                                        </small>
                                    <br>`;

                                        } else {

                                            if (table.LessonType == 3) {

                                                lessons = lessons + "<span data="+table.Data+" class='btn btn-block btn-primary aa btn-sm'>√ <strong>" + table.ClassRoomName + "</strong> <small class='dnone'> غير متزامن (معياري)</small></span>";
                                                ua = `<small class='text-primary'>
                                                    غير متزامن (معياري)
                                                    <br>
                                        <small>
                                        تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                        </small>
                                            </small>
                                        <br>`;

                                            } else {

                                                lessons = lessons + "<span class='btn btn-block btn-danger aa btn-sm'>X " + table.ClassRoomName + "</span>";
                                                ua = `<small class='text-danger'>
                                                لم يتم إعداد الدرس
                                            </small>
                                            <br>`;

                                            }

                                        }

                                    }

                                    details = `
                                <div class='info' style='display: none; border: 1px solid #eee; padding: 2px;'>
                                    <small>
                                        عنوان الدرس: ` + table.SubjectName + `
                                    </small>
                                    <br>
                                    <small>
                                        التوقيت: ` + table.StartTime + ` - ` + table.EndTime + `
                                    </small>
                                    <br>
                                    ` + ua + `
                                </div>
                                `;

                                    lessons = lessons + details;

                                }

                            });
                            lessons = lessons + "</td>";

                        });

                        $("tbody").append(`
                        <tr>
                            <td>` + index + `</td>
                            <td>` + teacher.name + `</td>
                            ` + lessons + `
                        </tr>
                    `);
                        index = index + 1;

                    }else{
            index = index + 1;
            var lessons = "";

            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(function (i) {

                lessons = lessons + "<td></td>";

            })
            $("tbody").append(`
            <tr>
                <td>` + index + `</td>
                <td>` + teacher.name + `</td>
                ` + lessons + `
            </tr>
        `);
                    }

                });
                $(".table-responsive").append(`
                <table class='table table-bordered table-sm'>
                    <thead>
                    <th>
                        
                    </th>
                        <th>
                            
                        </th>
                        <th>
                            كل الدروس
                        </th>
                        <th>
                            عدد الدروس الغير متزامنة
                        </th>
                        <th>
                            عدد الدروس المُعدة
                        </th>
                        <th>
                            عدد الدروس الغير المُعدة
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                        <td>
                        </td>
                            <td>
                                الإجمالي:
                            </td>
                            <td class='ag1'>
                            
                            </td>
                            <td class='ag2'>
                            
                            </td>
                            <td class='ag3'>
                            
                            </td>
                            <td class='ag4'>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
                    `);
                    $(".ag1").html($(".aa").length)
                    $(".ag2").html($(".btn-info.aa").length)
                    $(".ag3").html($(".btn-success.aa").length + $(".btn-info.aa").length)
                    $(".ag4").html($(".btn-danger.aa").length)
                    $(".aa").click(function () {

                        $(this).parent().find(".info").toggle();

                    });

            }).catch(function(){
                alert("حدث خطأ")
            })
        }else{
            var int = setInterval(() => {
            if($(".get-local-storage-value")?.length){
                clearInterval(int)
                var result = JSON.parse($(".get-local-storage-value").val())
                $(".get-local-storage-value").remove()
                $("body").append(`<textarea class='get-local-storage' name="datas" style='display: none'></textarea>`)
                var cscs = setInterval(function(){
                    if($(".get-local-storage-value")?.length){
                        clearInterval(int)
                        if($(".get-local-storage-value").val() != "undefined"){
                        g.datas = JSON.parse($(".get-local-storage-value").val())
                        }
                        $(".get-local-storage-value").remove()
                    }
                })

                g.result = result
                var index = 1;
                $.each(result, function (i, teacher) {

                    $("#date").html(teacher.data.CurrentSelectedDateStringHijri + " || " + teacher.data.CurrentSelectedDateString)

                    if (teacher.data.TimeTable[0]) {

                        var lessons = "";
                        var details = "";
                        var ua = "";

                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(function (i) {

                            lessons = lessons + "<td>";

                            $.each(teacher.data.TimeTable, function (ia, table) {

                                if (table.SlotNumber == i) {

                                    if (table.PresenterUrl != null && table.PresenterUrl != "") {

                                        lessons = lessons + "<span data="+table.Data+" class='btn btn-block btn-success aa btn-sm'>√ <strong>" + table.ClassRoomName + "</strong></span>";
                                        ua = `
                                    <small>
                                    رابط Teams: <a target='_blank' href='` + table.PresenterUrl + `'>إضغط هنا</a>
                                    </small>
                                    <br>
                                    <small>
                                    تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                    </small>
                                    <br>
                                    `;

                                    } else {

                                        if (table.LessonType == 2) {

                                            lessons = lessons + "<span data="+table.Data+" class='btn btn-block btn-info aa btn-sm'>√ <strong>" + table.ClassRoomName + "</strong> <small class='dnone'><br>غير متزامن</small></span>";
                                            ua = `<small class='text-primary'>
                                                غير متزامن
                                                <br>
                                    <small>
                                    تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                    </small>
                                        </small>
                                    <br>`;

                                        } else {

                                            if (table.LessonType == 3) {

                                                lessons = lessons + "<span data="+table.Data+" class='btn btn-block btn-primary aa btn-sm'>√ <strong>" + table.ClassRoomName + "</strong> <small class='dnone'> غير متزامن (معياري)</small></span>";
                                                ua = `<small class='text-primary'>
                                                    غير متزامن (معياري)
                                                    <br>
                                        <small>
                                        تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                        </small>
                                            </small>
                                        <br>`;

                                            } else {

                                                lessons = lessons + "<span class='btn btn-block btn-danger aa btn-sm'>X " + table.ClassRoomName + "</span>";
                                                ua = `<small class='text-danger'>
                                                لم يتم إعداد الدرس
                                            </small>
                                            <br>`;

                                            }

                                        }

                                    }

                                    details = `
                                <div class='info' style='display: none; border: 1px solid #eee; padding: 2px;'>
                                    <small>
                                        عنوان الدرس: ` + table.SubjectName + `
                                    </small>
                                    <br>
                                    <small>
                                        التوقيت: ` + table.StartTime + ` - ` + table.EndTime + `
                                    </small>
                                    <br>
                                    ` + ua + `
                                </div>
                                `;

                                    lessons = lessons + details;

                                }

                            });
                            lessons = lessons + "</td>";

                        });

                        $("tbody").append(`
                        <tr>
                            <td>` + index + `</td>
                            <td>` + teacher.name + `</td>
                            ` + lessons + `
                        </tr>
                    `);
                        index = index + 1;

                    }else{
            index = index + 1;
            var lessons = "";

            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(function (i) {

                lessons = lessons + "<td></td>";

            })
            $("tbody").append(`
            <tr>
                <td>` + index + `</td>
                <td>` + teacher.name + `</td>
                ` + lessons + `
            </tr>
        `);
                    }

                });
                $(".table-responsive").append(`
                <table class='table table-bordered table-sm'>
                    <thead>
                    <th>
                        
                    </th>
                        <th>
                            
                        </th>
                        <th>
                            كل الدروس
                        </th>
                        <th>
                            عدد الدروس الغير متزامنة
                        </th>
                        <th>
                            عدد الدروس المُعدة
                        </th>
                        <th>
                            عدد الدروس الغير المُعدة
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                        <td>
                        </td>
                            <td>
                                الإجمالي:
                            </td>
                            <td class='ag1'>
                            
                            </td>
                            <td class='ag2'>
                            
                            </td>
                            <td class='ag3'>
                            
                            </td>
                            <td class='ag4'>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
                    `);
                    $(".ag1").html($(".aa").length)
                    $(".ag2").html($(".btn-info.aa").length)
                    $(".ag3").html($(".btn-success.aa").length + $(".btn-info.aa").length)
                    $(".ag4").html($(".btn-danger.aa").length)
                    $(".aa").click(function () {

                        $(this).parent().find(".info").toggle();

                    });

            }
        }, 100);
        }
    },
    methods: {
        printDiv2(){
            $(".dropdown-toggle").fadeOut(0)
            setTimeout(() => {
                window.print()
                $(".dropdown-toggle").fadeIn()
            }, 500);
        },
        sendNow(method){
            var g = this;
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: localStorage.getItem('school_id'),
                arr: JSON.stringify(g.teachers),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'teachers'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        viewdetails(){
            var g = this;
            $(".aa").each(function(){
                $(this).click()
            })
        },
        teachersMessages(){
            var g = this;
            var arr = [], teachers = [];
            g.view_archive = false
            g.loading = false
            $(".ddcc tr").each(function () {

                if ($(this).find(".btn-danger").length) {

                    var less = [];
                    var full = [];

                    $(this).find(".btn-danger").each(function () {

                        less.push({
                            lesson: $(".ddcc th:nth-child(" + ($(this).parent().index() + 1) + ")").text().trim(),
                            classname: $(this).text().replace("X", '').replace("√", '').trim()
                        });

                        full.push($(".ddcc th:nth-child(" + ($(this).parent().index() + 1) + ")").text().trim() + ' للفصل ' + $(this).text().replace("X", '').replace("√", '').trim())

                    });

                    g.lessons[$(this).find('td:nth-child(2)').text().trim()] = full

                    arr.push({
                        name: $(this).find('td:nth-child(2)').text().trim(),
                        lessons: less,
                        full: full
                    })

                }

            });
            $.each(arr, function (i0, l) {

                l.phone = '';

                $.each(g.result, function (i, teacher) {
                    if (l.name == teacher.name) {
                        l.phone = teacher.phone
                    }
                });

                var message = g.message?.replace("{name}", l.name)?.replace("{lessons}", l.full.join(' , '))?.replace("{day}", g.days[new Number(g.dayindex)])?.replace("{date}", $("#date").text().split("||")[0].trim())

                teachers.push({
                    name: l.name,
                    phone: l.phone,
                    message: message
                })

            });
            this.teachers = teachers
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
        },
        teachersMessages3(){
            var g = this;
            g.message = `الاستاذ {name}
تم اعداد الدروس التالية غير متزامنة:
{lessons}
ليوم {day} الموافق {date}`;
            var arr = [], teachers = [];
            g.view_archive = false
            g.loading = false
            $(".ddcc tr").each(function () {

                if ($(this).find(".btn-info").length) {

                    var less = [];
                    var full = [];

                    $(this).find(".btn-info").each(function () {

                        less.push({
                            lesson: $(".ddcc th:nth-child(" + ($(this).parent().index() + 1) + ")").text().trim(),
                            classname: $(this).text().replace("X", '').replace("√", '').trim()
                        });

                        full.push($(".ddcc th:nth-child(" + ($(this).parent().index() + 1) + ")").text().trim() + ' للفصل ' + $(this).text().replace("X", '').replace("√", '').trim())

                    });

                    g.lessons[$(this).find('td:nth-child(2)').text().trim()] = full

                    arr.push({
                        name: $(this).find('td:nth-child(2)').text().trim(),
                        lessons: less,
                        full: full
                    })

                }

            });
            $.each(arr, function (i0, l) {

                l.phone = '';

                $.each(g.result, function (i, teacher) {
                    if (l.name == teacher.name) {
                        l.phone = teacher.phone
                    }
                });

                var message = g.message?.replace("{name}", l.name)?.replace("{lessons}", l.full.join(' , '))?.replace("{day}", g.days[new Number(g.dayindex)])?.replace("{date}", $("#date").text().split("||")[0].trim())

                teachers.push({
                    name: l.name,
                    phone: l.phone,
                    message: message
                })

            });
            this.teachers = teachers
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
        },
        sendToTeachers(){
            var g = this;
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#teachers-lessons-send")
        },
        sendToTeachers3(){
            var g = this;
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#teachers-lessons-send3")
        },
        saveToArchive(){
            var g = this;
            var title = prompt("اكتب عنوان للتقرير", 'تقرير تحضير الدروس ' + $("#date").text().split('||')[0])
            if(title){
                $.post(api + '/api/reports/save', {
                    school_id: window.location.href.split("school_id=")[1].split("&")[0],
                    title: title,
                    content: JSON.stringify(g.$data),
                    type: 'teachers-lessons'
                }).then(function(){
                    alert("تم حفظ التقرير بنجاح!")
                }).catch(function(){
                    alert("حدث خطأ")
                })
            }
        },
        exportToExcel() {
             $(".info").each(function(){
                if(!$(this).is(":visible")){
                    $(this).remove()
                }
            })
            $("td").each(function(){
                if($(this).find(".btn-success").length){
                    $(this).attr("style", `background: #97DECE`)
                }
                if($(this).find(".btn-danger").length){
                    $(this).attr("style", `background: #FD8A8A`)
                }
                if($(this).find(".btn-info").length){
                    $(this).attr("style", `background: #82AAE3`)
                }
            })
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").innerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
            setTimeout(() => {
                window.location = window.location.href
            }, 1000);
        },
        printDiv() {
            document.getElementById("date2").innerHTML = document.getElementById("date").innerHTML
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .btn-danger{
                    background: #FD8A8A;
                }
                .btn-success{
                    background: #97DECE;
                }
                .btn-info{
                    background: #82AAE3;
                }
                .btn{
                    width: 100% !important;
                    padding-left: 2px;
                    padding-right: 2px;
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        messageChanged(){
            var g = this, c = [];
            g.teachers.forEach(function(a){
                a.message = g.message?.replace("{name}", a.name)?.replace("{lessons}", g.lessons[a.name].join(' , '))?.replace("{day}", g.days[new Number(g.dayindex)])?.replace("{date}", $("#date").text().split("||")[0].trim())
                c.push(a)
            })
            g.teachers = c
        },
        datasGet(){
            var g = this;
            g.datas_arr = [];
            $("[data]").each(function(){
                if(g.datas[$(this).attr('data')]){
                    g.datas_arr.push({
                        data: g.datas[$(this).attr('data')],
                        teacher: $(this).parent().parent().find("td:nth-child(2)").text(),
                        classname: $(this).find("strong").text(),
                        lesson: $(this).parent().parent().parent().parent().find("thead th:nth-child("+($(this).parent().index()+1)+")").text()
                    })
                }
            })
        },
        printb(){
            var g = this;
            document.getElementById("date3").innerHTML = document.getElementById("date").innerHTML
            var divToPrint=document.getElementById("printb");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .btn-danger{
                    background: #FD8A8A;
                }
                .btn-success{
                    background: #97DECE;
                }
                .btn-info{
                    background: #82AAE3;
                }
                .btn{
                    width: 100% !important;
                    padding-left: 2px;
                    padding-right: 2px;
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        senddates(){
            var g = this;
            var obj = {};
            var phones = {}
            $("#printb tbody tr").each(function(){
                var name = $(this).find("td").first().text().trim();
                if(!obj[name]){
                    obj[name] = []
                    $.each(g.result, function (i, teacher) {
                        if (name == teacher.name) {
                            phones[name] = teacher.phone
                        }
                    });
                }
                obj[name].push({
                    classroom: $(this).find("td:nth-child(2)").text().trim(),
                    lesson: $(this).find("td:nth-child(3)").text().trim(),
                    items: $(this).find("td:nth-child(4)").text().trim().split('  ').join(","),
                })
            })
            var g = this;
            var teachers = [];
            g.view_archive = false
            g.loading = false
            for (const [key, value] of Object.entries(obj)) {
                var message = g.message?.replace("{name}", key)?.replace("{items}", value.map(function(x){
                    return x.items?.trim() == '' ? false : `الفصل: ${x.classroom}\nالحصة: ${x.lesson}\nالعناصر: ${x.items}`
                }).filter(function(x){
                    return x;
                }).join('\n-------\n'))?.replace("{day}", g.days[new Number(g.dayindex)])?.replace("{date}", $("#date").text().split("||")[0].trim())
                teachers.push({
                    name: key,
                    phone: phones[key],
                    message: message
                })
            }
            this.teachers = teachers
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#teachers-lessons-send2")
        }
    }
}
</script>

<style>
.dropdown-menu{
    width: 300px !important
}
</style>